import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import { Col, Container, Row } from "react-bootstrap";

const IndexPage = () => {
	// useEffect(() => {
	// 	if (typeof window !== "undefined") {
	// 		window.location.href = "/admin/home";
	// 	}
	// }, []);

	return (
		<>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo title="Index" language="en" noindex nofollow />

			<div style={{ background: "#F8F8F8" }}>
				<Navigation />
				<section
					className="bg-white py-5 py-lg-7"
					style={{ minHeight: "95vh" }}
				>
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h1 className="display-5 mb-4">
									Not where you were expecting to be?
								</h1>
								<p>Let’s get you back on track.</p>
								<p>
									{" "}
									It seems you've stumbled upon a little hiccup. You're here
									because a link from us led you astray, but worry not - we're
									here to guide you back on track.
								</p>
								<p>
									{" "}
									We understand that this can be frustrating, so let's solve
									this together. Whether it's about an event detail, a query, or
									just saying hello, we're all ears.
								</p>
								<p>
									{" "}
									Let's figure out what you're looking for and how we can assist
									you. Reach out to us, and we'll ensure your event journey is
									smooth sailing from here on out.
								</p>
								<p>
									{" "}
									Thank you for your patience and understanding. We're looking
									forward to making your event experience seamless and
									memorable.
								</p>
								<p>Thank you, </p>
								<p>Richmond Events Team</p>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
};

export default IndexPage;
